<template>
  <div class="wrapper">
    <side-bar>
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link class="sidebar-control reduce-menu" :link="{name: $t('reduce_menu'), icon:'fas fa-angle-double-left', path:'reduceSidebar'}"/>
      <sidebar-link class="sidebar-control extend-menu" :link="{name: $t('extend_menu'), icon:'fas fa-angle-double-right', path:'extendSidebar'}"/>
      <!--<sidebar-link class="sidebar-control close-menu" :link="{name: $t('close_menu'), icon:'fas fa-times', path:'closeSidebar'}"/>-->
      <sidebar-link class="sidebar-control open-menu" :link="{name: $t('open_menu'), icon:'fas fa-angle-double-right', path:'openSidebar'}"/>
      <div v-if="user.rol_id == 1">
        <sidebar-item :link="{name: $t('Companias'), icon:'fas fa-address-book'}">
            <sidebar-item :link="{name: $t('Listado'), icon:'far fa-address-card', path:'/admin/contacts'}"/>
            <!-- <sidebar-item :link="{name: $t('NuevasEntradas'), icon: 'fas fa-envelope',  path: '/admin/ '}"/>
            <sidebar-item :link="{name: $t('Tramites'), icon:'fas fa-file-alt', path:'/admin/mediaciones'}"/> -->
        </sidebar-item>
        <li class="nav-item">
          <a class="nav-link sidebar-menu-item border-bottom d-flex align-items-center mx-2" href="/admin/documents">
            <i class="fas fa-folder"></i>
            {{$t("GestorDocumental")}}
          </a>
        </li>
        <!--<sidebar-item :link="{name: $t('GestorDocumental'), icon:'fas fa-folder'}">
         <sidebar-item :link="{name: $t('folders'), icon:'far fa-folder-open', path:'/admin/documents'}"/>
         <sidebar-item :link="{name: $t('Documentos'), icon:'far fa-copy', path:'/admin/documentos'}"/>
         <sidebar-item :link="{name: $t('Estadisticas'), icon:'far fa-chart-bar', path:'/admin/documents_statistics'}"/>
       </sidebar-item>-->
        <!-- <sidebar-item :link="{name: $t('Concursal'), icon:'fas fa-money-check', path:'/admin/ranking_ac'}">
            <sidebar-item :link="{name: $t('Ranking_AC'), icon:'fas fa-chart-bar',  path: '/admin/ranking_ac'}"></sidebar-item>
            <sidebar-item :link="{name: $t('Administradores'), icon: 'fas fa-users',  path: '/admin/admin_concursal'}"></sidebar-item>
                <sidebar-item :link="{name: $t('concursos'), icon: 'fas fa-hands-helping', path: '/admin/concursos'}"></sidebar-item>
        </sidebar-item> -->
        <sidebar-item :link="{name: $t('config'), icon:'fas fa-cog'}">                             
            <sidebar-item :link="{name: $t('Usuarios'), icon:'far fa-user', path:'/admin/users'}"/>      
            <sidebar-item :link="{name: $t('sol_documentos'), icon:'far fa-file-alt', path:'/admin/solicitud_doc'}"/>      
        </sidebar-item>                        
      </div>
      <div v-if="user.rol_id == 2">
        <!-- <sidebar-item :link="{name: $t('Cliente'), icon:'fas fa-user'}">                             
          <sidebar-item :link="{name: $t('DatosCliente'), icon:'fas fa-id-card', path:'/admin/ficha_cliente'}"/>      
        </sidebar-item> -->
        <sidebar-item :link="{name: $t('GestorDocumental'), icon:'fas fa-folder', collapsed: false}">                             
          <sidebar-item :link="{name: $t('folders'), icon:'far fa-folder-open', path:'/admin/carpetas'}"/>      
          <sidebar-item :link="{name: $t('Documentos'), icon:'far fa-copy', path:'/admin/documentos'}"/>
        </sidebar-item>      
      </div>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar"></dashboard-content>

      <!-- <content-footer></content-footer> -->
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import { mapGetters } from 'vuex';
  import TopNavbar from './TopNavbar.vue'
  // import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './MobileMenu.vue'
  export default {
    computed: {
        ...mapGetters({
            user: 'login/user'
        })
    },
    components: {
      TopNavbar,
      // ContentFooter,
      DashboardContent,
      MobileMenu
    },
    methods: {
      toggleSidebar ()
      {
        console.log("toggleSidebar");
        this.$sidebar.toggleSidebar = !this.$sidebar.toggleSidebar;
      }
    }
  }
</script>
