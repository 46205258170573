<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <!--<img src="@/assets/images/logo_cleverdocs_blanc.png" alt="" height="55px" width="130px">-->
        <img src="https://cleverdocs.cleverbsolutions.com/img/logo_cleverdocs.e80bdb8e.png" alt="" height="55px" width="130px">
      </a>
      <h4 v-if="user.client" class="text-white2 text-uppercase py-0 my-0">{{user.client.name}}</h4>
      <button type="button" style="display:none" @click="countDocuments">
      COUNTING
      </button>
      <button type="button"
              class="navbar-toggler navbar-toggler-right"
              :class="{toggled: this.$sidebar.toggleSidebar}"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="nav navbar-nav mr-auto">
          <!--<li class="nav-item">
           <a class="nav-link" href="#" data-toggle="dropdown">
             <i class="nc-icon nc-palette"></i>{{user.name}} - {{user.rol_id}}
           </a>
         </li>
          <li class="nav-item">
           <a href="#" class="nav-link">
             <i class="nc-icon nc-zoom-split"></i>
             <span class="d-lg-block">&nbsp;Search</span>
           </a>
         </li> -->
        </ul>
        <ul class="navbar-nav ml-auto">
          <!--
          <base-dropdown tag="li">
            <template slot="title">
                  <img :src="'/'+lang+'.png'" width="30px">
            </template>
            <a class="dropdown-item" @click="onChangeLang('es')">                  
              <img src="@/assets/images/es.png" width="30px">
              {{$t("lang_es")}}
            </a>
             <a class="dropdown-item" @click="onChangeLang('cat')">                  
              <img src="@/assets/images/cat.png" width="30px">
              {{$t("lang_cat")}}
            </a>
            <a class="dropdown-item" @click="onChangeLang('en')">                  
              <img src="@/assets/images/en.png" width="30px">
              {{$t("lang_en")}}
            </a>
          </base-dropdown>
          -->
          <!--<base-dropdown class="notifications-contents" tag="li" :title="$t('documentos')">
               <template slot="title">
               <i class="notification-icon fas fa-file-alt no-line"></i>
               <b class="caret"></b>
               <span class="notification" v-if="document_not.length > 0">{{document_not.length}}</span>
               </template>
               <div v-if="document_not">
                 <router-link class="dropdown-item" :to="'/admin/documents/' + item.parent_id"  v-bind:class="{'bg-light' : index % 2 == 0}" v-for="(item, index) in document_not" v-bind:key="index">
                   <div class="p-1 d-flex align-items-center justify-content-between">
                       <div class="mr-3">
                       <span class="font-weight-bold font12 text-uppercase" v-if="item.user">{{item.user.name}}</span>
                       <span class="font10">
                           {{$t("add_document")}} {{item.total}} {{item.total == 1 ? $t("documento") : $t("documentos")}} {{$t("en")}} {{item.document ? item.document.name : $t("INICIO")}}
                       </span>
                       </div>
                       <i class="fas fa-upload font18"></i>
                   </div>
                 </router-link>
               </div>
           </base-dropdown>-->
          <!--GOOD-->

          <!--<base-dropdown tag="li" class="notifications-dropdown" v-if="user.rol_id == 1">
            <template slot="title">
              <i class="notification-icon fas fa-bell no-line"></i>
              <b class="caret"></b>
              <span class="notification" v-if="notifications.length >= 1">{{notifications.length}}</span>
            </template>
            <div class="py-2" v-for="(item, index) in notifications" v-bind:key="index" :class="{'bg-light' : index % 2 == 0}">
              <router-link  tag="a" :to="'/admin/solicitud/' + item.id" class=" dropdown-item"  >
                <div class="row">                
                  <div class="col-2 px-0 d-flex align-items-center justify-content-center ">
                      <i class="fas fa-envelope font30"></i>  
                  </div>
                  <div class="col-10" v-if="item.contact">
                    {{item.service_type.name}} | {{item.contact.name}} {{item.contact.lastname}}
                    <div class="d-flex items-align-end  justify-content-end font12">
                      <span>{{item.created_at | moment("HH:MM DD/MM/YYYY ")}}</span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </base-dropdown> -->
          <base-dropdown v-if="user" :title="user.name">
            <!-- <a class="dropdown-item " href="#">{{$t("Perfil")}}</a> -->
            <router-link tag="a" class="dropdown-item" to="/admin/change_password">{{$t("change_password")}}</router-link>
            <a class="dropdown-item " @click="showNotificationsModal()">{{$t("submenu_noti_title")}}</a>
            <div class="divider"></div>
            <button class="dropdown-item" @click="logout()" >{{$t("Salir")}}</button>
          </base-dropdown>
        </ul>
      </div>
    </div>
    <modal @accept="onSaveNotifications ()" :FlText="$t('Cancelar')" :size="'lg'" :btnAcceptText="$t('Guardar')" @close="modal_noti=false" :showClose="true" classContent="contentModal2" v-if="modal_noti" :showfooter="true" :showBtnCancel="false" :title="$t('Avisarme cuando se realicen cambios')">
      <div class="row">
        <div class="col-5">
          <label>{{$t("Titulo de la Notificación")}}</label>
        </div>
        <div style="text-align: right" class="col-7"><input v-model="titleNoti" @input="readTitle()" class="titleNoti" name="title_noti" type="text"/></div>
      </div>
      <hr>
      <div class="row">
        <div class="col-5">
          <label>{{$t("Cuando enviar la notificación")}}</label>
        </div>
        <div style="text-align: right" class="col-7 noti_group">
          <label>{{$t("Nunca")}} <input v-model="notification_time" @click="onChangeNotification(0)" name="notiGroup" value="0" type="radio"/></label>
          <label>{{$t("Si se sube un archivo o carpeta")}} <input v-model="notification_time" @click="onChangeNotification(1)" name="notiGroup" value="1" type="radio"/></label>
          <label>{{$t("Si se comenta un archivo")}} <input v-model="notification_time" @click="onChangeNotification(2)" name="notiGroup" value="2" type="radio"/></label>
          <label>{{$t("Las dos opciones anteriores")}} <input v-model="notification_time" @click="onChangeNotification(3)" name="notiGroup" value="3" type="radio"/></label>
          <!--<label>{{$t("Siempre")}} <input @click="onChangeNotification(3)" name="notiGroup" value="3" :checked="this.checkedNoti == '3'" type="radio"/></label>-->
        </div>
      </div>
      <!--<div class="modal_button calibri px-3 text-justify">
        <div class="mb-4 text-center ">
          <button class="btn btn-md btn-primary btn-fill my-1 px-5 py-1 font-weight-bold">
            <i class="fas fa-check mr-2"></i>
            {{$t('aceptar')}}
          </button>
        </div>
      </div>-->
    </modal>
    <modal :size="'lg'" class="w-full" classContent="contentModal" v-if="modal_lodp" :showfooter="false" :showBtnCancel="false" :title="$t('ley_lopd')">
      <div class="calibri px-3 text-justify">
        <ley_rgpd></ley_rgpd>
        <div class="mb-4 text-center ">
          <button class="btn btn-md btn-primary btn-fill my-1 px-5 py-1 font-weight-bold" @click="onAcceptLopd">
            <i class="fas fa-check mr-2"></i>
            {{$t('aceptar')}}
          </button>
        </div>
      </div>
    </modal>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import ley_rgpd from "@/components/ley_rgpd.vue"
import Modal from "@/components/Modal.vue";

  export default {
      
    data () {
      return {
        notification_time:this.onChangeNotification(3),
        titleNoti: "",
        checkedNoti: "3",
        activeNotifications: false,
        modal_lodp : false,
        modal_noti : false,
        isAdmin : false
      }
    },
    computed: {
        ...mapGetters({
            user: 'login/user',
            notifications: 'login/notifications',
            lang: 'login/lang',
            document_not : 'login/document_notifications'
        })
    },
    components: {
      ley_rgpd,
      Modal
    },
    created ()
    {
      this.isAdmin = this.user.rol_id == 1;
      this.onLoadNotifications ();
      if (this.user.rol_id == 2) this.onLoadModalRgpd ();

    },
    methods: {

      onChangeLang (locale)
      {
        this.$i18n.locale = locale;
        this.$store.dispatch ('login/changeLang', locale);
      },
      capitalizeFirstLetter (string)
      {
        return string.charAt (0).toUpperCase () + string.slice (1)
      },
      onLoadNotifications ()
      {
        if (this.isAdmin)
        {
          this.$store.dispatch ('login/onLoadNotifications');
          this.$store.dispatch ('login/documentNotifications');        
        }
      },
      toggleNotificationDropDown ()
      {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown ()
      {
        this.activeNotifications = false;
      },
      countDocuments()
      {
        console.log("countDocuments");
        this.$store.dispatch ('isLoadingOn');
        this.$axios ("/document/total_docs")
            .then ((response) => {
              console.log(response);
              this.$store.dispatch ('isLoadingOff');
            })
            .catch (() => {
              this.$store.dispatch ('isLoadingOff');
            })
      },
      toggleSidebar ()
      {
        this.$sidebar.toggleSidebar = !this.$sidebar.toggleSidebar;
      },
      showNotificationsModal(){
        console.log(this.user.id);
        let data = {
          user_id            : this.user.id,
        };
        this.$axios.post ("/client/get_user", data)
            .then ((response) => {
              console.log(response.data);
              this.titleNoti = response.data.title_notification != " " ? response.data.title_notification : 'Notificación';
              this.notification_time=response.data.notification;
              this.modal_noti = true;
            })
            .catch (() => {
              this.$store.dispatch ('isLoadingOff');
            })
      },
      logout ()
      {
        this.$store.dispatch ('login/logout');
      },
      onSaveNotifications (){
        let data= {
          notification            : this.checkedNoti,
          title_notification      : this.titleNoti,
        };
        console.log("Save");
        this.$store.dispatch ('isLoadingOn');
        this.$axios.post ("/client/save_noti", data)
            .then ((response) => {
              console.log(response.data);
              this.modal_noti = false;
              this.$store.dispatch ('isLoadingOff');
            })
            .catch (() => {
              this.$store.dispatch ('isLoadingOff');
            })
      },
      readTitle(){
        console.log(this.titleNoti);
      },
      onChangeNotification(value){
        this.checkedNoti = value;
        console.log(this.checkedNoti);
      },
      onAcceptLopd ()
      {
        this.$store.dispatch ('isLoadingOn');
        this.$axios ("/client/accept_lodp")
        .then (() => {
          this.modal_lodp = false;
          this.$store.dispatch ('isLoadingOff');
        })
        .catch (() => {
          this.$store.dispatch ('isLoadingOff');
        })
      },  
      onLoadModalRgpd ()
      {
        this.$store.dispatch ('isLoadingOn');
        this.$axios ("/client/check_lodp")
        .then ((response) => {
          let user = response.data;
          console.log(response.data);
          this.modal_lodp = !user.lodp_accept;
          this.$store.dispatch ('isLoadingOff');
        })
        .catch (() => {
          this.$store.dispatch ('isLoadingOff');
        })
      }  
    }
  }

</script>
<style>
.navbar .navbar-toggler .burger-lines{
  background: #888 !important;
}
.no-line{
  line-height: initial !important;
}
.dropdown-menu{
  margin-top: 5px  !important;
  border-radius: 0px;
}
.dropdown-menu div
{
  max-height: calc(100vh - 76px);
  overflow: auto;
}
.font10{
  font-size:10px;
}
.font12{
  font-size:12px;
}
.font14{
  font-size:14px;
}
.font18{
  font-size:18px;
}
.font30{
  font-size:30px;
}
.notifications-dropdown .dropdown-menu{
  width:400px;
}
.notifications-dropdown  .dropdown-item{
  padding: 0.25rem 1.5rem;

}
.calibri{
    font-family: "calibri light" !important;
}
.contentModal{
  height: 600px;
  overflow: hidden scroll;
}
.contentModal2{
  height: 200px;
}
.contentModal2 input.titleNoti{
  width: 100%;
}
.noti_group label {width: 100%; text-transform: none;}
.dropdown-item{cursor: pointer}
.modal_button{

  position: absolute;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
}
.modal-lg {
  max-width: 600px;
}
.modal-xl {
  max-width: 750px;
}
@media (min-width: 992px)
{
 .w-full .modal-dialog{
    max-width: 80%;
  } 
}
.navbar .navbar-nav .nav-item .nav-link{ color: #888888}
.navbar-brand{padding-top: 0px; padding-bottom: 0px}

@media (max-width: 40em) {
  .card-header img{
    width: 90%;
  }

}

.navbar-brand img{
  width: 100%;
}
</style>
