<template>
  <ul class="nav nav-mobile-menu">
   <base-dropdown v-if="user" :title="user.name">
      <!-- <a class="dropdown-item">{{$t("Perfil")}}</a> -->
      <router-link tag="a" class="dropdown-item" to="change_password">{{$t("change_password")}} </router-link>
      <a class="dropdown-item " @click="showNotificationsModal()">{{$t("Gestionar notificaciones")}}</a>
      <div class="divider"></div>
      <a class="dropdown-item" @click="logout()">{{$t("Salir")}}</a>
    </base-dropdown>
  </ul>
</template>
<script>
import { mapGetters } from 'vuex';

  export default {

    data () {
      return {
        notification_time:3,
        titleNoti: "",
        checkedNoti: "",
        activeNotifications: false,
        modal_lodp : false,
        modal_noti : false,
        isAdmin : false
      }
    },
    name: 'mobile-menu',
    computed: {
        ...mapGetters({
            user: 'login/user'
        })
    },
    methods : {
      showNotificationsModal(){
        console.log(this.user.id);
        let data = {
          user_id            : this.user.id,
        };
        this.$axios.post ("/client/get_user", data)
            .then ((response) => {
              console.log(response.data);
              this.titleNoti = response.data.title_notification != " " ? response.data.title_notification : 'Notificación';
              this.notification_time=response.data.notification;
              this.modal_noti = true;
            })
            .catch (() => {
              this.$store.dispatch ('isLoadingOff');
            })
      },
      logout() {
          this.$store.dispatch('login/logout');
      },
    }
  }
</script>
<style>
</style>
